import React, { useState, Suspense, lazy } from "react";
import "./App.css";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import firebase from "./config/firebase";
import AuthProvider from "./Contexts/AuthContext";
const Home = lazy(() => import("./pages/Home"));
const NavBar = lazy(() => import("./components/NavBar"));
const Footer = lazy(() => import("./components/Footer"));
const Services = lazy(() => import("./pages/Services"));
const ConfirmOrder = lazy(() => import("./pages/ConfirmOrder"));
const ScrollToTop = lazy(() => import("./components/ScrollToTop"));
const Signup = lazy(() => import("./components/Signup"));
const Profile = lazy(() => import("./pages/Profile"));
const OrderDetails = lazy(() => import("./pages/OrderDetails"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const Terms = lazy(() => import("./pages/Terms"));
const Contact = lazy(() => import("./pages/Contact"));

firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    let info = {
      id: user.uid,
      email: user.email,
      name: user.displayName,
      photo: user.photoURL,
    };
    localStorage.setItem("auth", JSON.stringify(info));
  } else {
    localStorage.removeItem("auth");
  }
});

function App() {
  const [showForm, setShowForm] = useState(false);
  return (
    <Router>
      <Suspense fallback={<div className="progress"></div>}>
        <ScrollToTop>
          <AuthProvider>
            <div className="App">
              <NavBar setShowForm={setShowForm} />
              <Signup show={showForm} setShowForm={setShowForm} />
              <div className="mainContainer">
                <Switch>
                  <Route exact path="/" component={Home} />
                  <Route exact path="/services" component={Services} />
                  <Route
                    exact
                    path="/confirm-service"
                    render={(routeProps) => (
                      <ConfirmOrder {...routeProps} setShowForm={setShowForm} />
                    )}
                  />
                  <Route exact path="/profile" component={Profile} />
                  <Route exact path="/privacy" component={PrivacyPolicy} />
                  <Route exact path="/terms" component={Terms} />
                  <Route exact path="/contact" component={Contact} />
                  <Route exact path="/details" component={OrderDetails} />
                </Switch>
              </div>
              <Footer />
            </div>
          </AuthProvider>
        </ScrollToTop>
      </Suspense>
    </Router>
  );
}

export default App;
