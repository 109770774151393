import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
var firebaseConfig = {
  apiKey: "AIzaSyAknVJhOsOBgd9IrzlcxnNEu1km9epQhSw",
  authDomain: "asyncus-46a93.firebaseapp.com",
  databaseURL: "https://asyncus-46a93.firebaseio.com",
  projectId: "asyncus-46a93",
  storageBucket: "asyncus-46a93.appspot.com",
  messagingSenderId: "149745114815",
  appId: "1:149745114815:web:6f3607523383348a74300a",
  measurementId: "G-WQVM47CHJ1",
};
firebase.initializeApp(firebaseConfig);
export default firebase;
